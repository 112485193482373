import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
const env_datas = environment.firebase;
const CALL_API = env_datas.api_url;

@Injectable({
  providedIn: 'root'
})
export class SupplierService {

  constructor(private http:HttpClient) { }

  addSupplier(data){
    return this.http.post(`${CALL_API}addSupplier`,data);

  }
  getSuppliers(){
    return this.http.get(`${CALL_API}getSuppliers`);

  }
  getSubCont(){
    return this.http.get(`${CALL_API}getSubCont`);

  }
  getMiscell(){
    return this.http.get(`${CALL_API}getMiscell`);

  }
  
  changeSupplierStatus(data)
  {
    return this.http.post(`${CALL_API}changeSupplierStatus`,data);

  }

  getSuppliersDetailsById(id){
    return this.http.get(`${CALL_API}getSuppliersById/`+id);

  }

  changeSupplierEquipmentStatus(data)
  {
    return this.http.post(`${CALL_API}changeSupplierEquipmentStatus`,data);

  }
  getLogsBySupplierId(id:any,ModuleId:any)
  {
    return this.http.get(`${CALL_API}getLogsBySupplierId/`+id+'/'+ModuleId);

  }

  getCategories()
  {
    return this.http.get(`${CALL_API}getCategories`);

  }
  getAllCategories(){
    return this.http.get(`${CALL_API}getAllCategory`);
  }

  getTypesByCatId(id){
    return this.http.get(`${CALL_API}getSuppliersById/`+id);

  }

  updateSupplier(data){
    return this.http.post(`${CALL_API}updateSupplier`,data);

  }
  updateSupplierEquipment(data){
    return this.http.post(`${CALL_API}updateSupplierEquipment`,data);

  }

  getEquipmentBySupplierId(id){
    return this.http.get(`${CALL_API}getEquipmentBySupplierId/`+id);

  }
  getCategoryTypes()
  {
    return this.http.get(`${CALL_API}categoryTypes`);

  }




}
